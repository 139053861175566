<template>
  <div>
    <button @click.prevent="openExportMonthlyStatementModal" class="btn btn-primary mb-4 me-4">
      <i class="bi bi-download me-1 mb-1"></i>Export monthly statement
    </button>

    <button @click.prevent="openExportInvoicesModal" class="btn btn-secondary mb-4">
      <i class="bi bi-download me-1 mb-1"></i>Export invoices
    </button>
    
    <div class="float-right">
      <div style="text-align: right;">
        <strong>Invoices amounts on selected filters (VAT excluded):</strong><br>
        <span class="d-inline-block">Total: {{ invoicesStats.grandTotal }}€</span><br>
        <span class="d-inline-block">✅ Paid: {{ invoicesStats.totalPaid }}€</span>
        /<span class="d-inline-block" style="margin: 0 8px;">⏳ Pending: {{ invoicesStats.totalPending }}€</span>
        /<span class="d-inline-block" style="margin-left: 8px;">⛔️ Rejected: {{ invoicesStats.totalRejected }}€</span>
      </div>
    </div>

    <ExportInvoices
      name="export-invoices-modal"
      v-model="isExportInvoicesModalOpen"
      :clinicsDropdownItems="clinicsDropdownItems"
      @cancel="closeExportInvoicesModal"
      @confirm="onInvoicesExported"
    />

    <ExportMonthlyStatement
      name="export-monthly-statement-modal"
      v-model="isExportMonthlyStatementModalOpen"
      :clinicsDropdownItems="clinicsDropdownItems"
      @cancel="closeExportMonthlyStatementModal"
      @confirm="onMonthlyStatementExported"
    />

    <!--begin::Table-->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: true }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
      @page-change="onPageChange"
      @sort-change="onSortChange"
      @column-filter="onColumnFilter"
      @per-page-change="onPerPageChange"
      styleClass="custom-table mt-3"
    >
      <template #loading-content>Loading...</template>
      <template #emptystate>No entry found</template>

      <template #column-filter="{ column }">
        <div v-if="column.filterOptions && column.filterOptions.customFilter">
          From
          <input
            type="date"
            @input="(value) => handleDateRangeFilter(`${column.field}_gte`, value)"
          ><br>
          To
          <input
            type="date"
            @input="(value) => handleDateRangeFilter(`${column.field}_lte`, value)"
          >
        </div>
      </template>

      <template #table-row="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <button
            @click.prevent="downloadInvoice(props.row)"
            title="Download Invoice"
            class="btn btn-secondary mr-4"
          >
            <i class="fas fa-file-download text-primary" />
            Download
          </button>
        </span>

        <!-- Column: Products -->
        <span
          v-else-if="props.column.field === 'products'"
          v-html="props.formattedRow.products"
        />

        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'paymentStatus'"
        >
          <span v-if="props.row.paymentCompleted" title="Payment completed">
            ✅
          </span>
          <span v-else-if="props.row.paymentRejected" title="Payment rejected">
            ⛔️
          </span>
          <span v-else title="Payment pending">
            ⏳
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template #pagination-bottom="props">
        <div
          class="d-flex justify-content-between flex-wrap align-items-center"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <select
              class="form-control form-control-sm me-3"
              :value="serverParams.perPage"
              @change="
                (e) => props.perPageChanged({ currentPerPage: e.target.value })
              "
            >
              <option
                v-for="(option, idx) in ['5', '10', '25', '50', '100']"
                :key="`select-perpage-${idx}`"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <span class="text-nowrap">orders per page </span>
          </div>
          <div>
            <ul class="pagination">
              <li
                class="page-item previous"
                :class="{ disabled: serverParams.page === 1 }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page - 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="previous"></i
                ></a>
              </li>
              <div
                v-for="idx in Math.ceil(props.total / serverParams.perPage)"
                class="d-flex flex-row align-items-center"
                :key="idx"
              >
                <li
                  class="page-item"
                  :class="{ active: serverParams.page === idx }"
                >
                  <a
                    @click.prevent="props.pageChanged({ currentPage: idx })"
                    href="#"
                    class="page-link"
                    v-if="
                      idx <= 3 ||
                      (idx >= serverParams.page - 1 &&
                        idx <= serverParams.page + 1) ||
                      idx >= Math.ceil(props.total / serverParams.perPage) - 2
                    "
                    >{{ idx }}</a
                  >
                </li>
                <p
                  class="mb-0 mt-1 mx-3"
                  v-if="
                    (idx === serverParams.page - 2 && idx > 3) ||
                    (idx === serverParams.page + 2 &&
                      idx < Math.ceil(props.total / serverParams.perPage) - 2)
                  "
                >
                  ...
                </p>
              </div>
              <li
                class="page-item next"
                :class="{
                  disabled:
                    serverParams.page ===
                    Math.ceil(props.total / serverParams.perPage),
                }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page + 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="next"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ExportInvoices from "@/components/modals/invoices/ExportInvoices";
import ExportMonthlyStatement from "@/components/modals/invoices/ExportMonthlyStatement";
import _ from "lodash";

export default {
  name: "InvoicesManager",
  components: {
    ExportInvoices,
    ExportMonthlyStatement,
  },
  data() {
    return {
      invoicesStats: {},
      isExportInvoicesModalOpen: false,
      isExportMonthlyStatementModalOpen: false,
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        {
          label: "Date",
          field: "createdAt",
          formatFn: e => new Date(e).toLocaleDateString(),
          sortable: true,
          filterOptions: { enabled: true, customFilter: true },
        },
        {
          label: "Number",
          field: "numberFormatted",
          sortable: true,
        },
        {
          label: "Customer (clinic)",
          field: "clinic",
          formatFn: e => e ? e.name : 'No',
          filterOptions: {
            enabled: true,
            filter: true,
            placeholder: 'All',
            filterDropdownItems: [],
          },
        },
        {
          label: "Related to",
          field: "order",
          formatFn: e => e ? `Order #${e.number}` : 'Hot prospects',
          filterOptions: {
            enabled: true,
            filter: true,
            placeholder: 'All',
            filterDropdownItems: [
              { value: "ORDERS", text: "Orders" },
              { value: "HOT_PROSPECTS", text: "Hot prospects" },
            ],
          },
        },
        // {
        //   label: "Detail",
        //   field: "lines",
        //   html: true,
        //   formatFn: e => e.map(line => {
        //     return `<small>${line.description}</small>`
        //   }).join('; '),
        // },
        {
          label: "Total excl. VAT",
          field: "totalWithoutVat",
          formatFn: e => `${e.toFixed(2)}€`,
          sortable: true,
        },
        // {
        //   label: "Discount",
        //   field: "discount",
        //   formatFn: e => e ? `${e}%` : '',
        // },
        {
          label: "Total incl. VAT",
          field: "totalWithVat",
          formatFn: e => `${e.toFixed(2)}€`,
          sortable: true,
        },
        {
          label: "Status",
          field: "paymentStatus",
          filterOptions: {
            enabled: true,
            filter: true,
            placeholder: 'All',
            filterDropdownItems: [
              { value: "PAID", text: "Paid" },
              { value: "PENDING", text: "Pending" },
              { value: "REJECTED", text: "Rejected" },
            ],
          },
        },
        {
          label: "",
          field: "action",
        },
      ],
      rows: [],
      clinicsDropdownItems: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "createdAt",
          type: "desc",
        },
        page: 1,
        perPage: 25,
      },
      dateFilters: {
        createdAt_gte: null,
        createdAt_lte: null,
      },
    };
  },
  mounted() {
    this.fetchItemsFromApi();
    setCurrentPageBreadcrumbs("Invoices", []);

    ApiService.query('clinics', { params: { limit: 100 } }).then(({ data }) => {
      this.clinicsDropdownItems = data.results.map(e => ({
        value: e.id,
        text: e.name,
      }));

      this.columns.find(e => e.field == 'clinic').filterOptions.filterDropdownItems = this.clinicsDropdownItems;
    });
  },
  methods: {
    updateParams(newProps) {
      // Force date filters
      if (newProps.columnFilters) {
        newProps.columnFilters = { ...newProps.columnFilters, ...this.dateFilters };
      }

      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      console.log("onColumnFilter", {...params})
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    handleDateRangeFilter: _.debounce(function (column, event) {
      let value = event.target.value;
      if (value) {
        value += column.endsWith("_gte") ? "T00:00:00" : "T23:59:59";
      }
      
      // Persist date filters
      this.dateFilters[column] = value;

      this.onColumnFilter({
        columnFilters: {
          ...this.serverParams.columnFilters,
          [column]: value
        }
      });
    }, 500),
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      console.log(this.serverParams.columnFilters);
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (['clinic', 'createdAt_gte', 'createdAt_lte', 'paymentStatus'].includes(e)) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else if (e == 'order') {
          if (this.serverParams.columnFilters[e] == "ORDERS") {
            tableSearch["is_order"] = true;
          } else { // HOT_PROSPECTS
            tableSearch['is_hot_prospect'] = true;
          }
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: "clinic order",
      };

      ApiService.query("/invoices?stats=1", { params: getApiParams })
        .then(({ data }) => this.invoicesStats = data);
      
      ApiService.query("/invoices", { params: getApiParams })
        .then(({ data }) => {
          this.rows = data.results;
          this.totalRecords = data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    downloadInvoice(invoice) {
      ApiService.query(`invoices/${invoice.id}/pdf`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      }).then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${invoice.numberFormatted}-neoris-invoice.pdf`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    openExportInvoicesModal() {
      this.isExportInvoicesModalOpen = true;
    },
    closeExportInvoicesModal() {
      this.isExportInvoicesModalOpen = false;
    },
    onInvoicesExported() {
      this.closeExportInvoicesModal();
    },
    openExportMonthlyStatementModal() {
      this.isExportMonthlyStatementModalOpen = true;
    },
    closeExportMonthlyStatementModal() {
      this.isExportMonthlyStatementModalOpen = false;
    },
    onMonthlyStatementExported() {
      this.closeExportMonthlyStatementModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.operations-table {
  background-color: #fff;
}
</style>
<style lang="scss">
.custom-table th.filter-th {
  color: #000;
  font-weight: normal;
}
</style>
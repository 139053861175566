<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content modal-export-invoices"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        Export monthly statement
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <form @submit.prevent="exportInvoices" ref="form">
        <div class="d-flex flex-row row mb-4">
          <!-- Begin::Left col -->
          <div class="d-flex flex-column col-6 mb-5">
            <label>Month / Year</label>
            <input
              type="month"
              class="form-control"
              v-model="exportSummaryStatement.month"
              required
            />
          </div>
          <!-- End::Left col -->
          <!-- Begin::Right col -->
          <div v-if="clinicsDropdownItems" class="d-flex flex-column col-6 mb-5">
            <p class="mb-1">Clinic</p>
            <select
              class="custom-select form-control"
              v-model="exportSummaryStatement.clinic"
              required
            >
              <option disabled>Choose...</option>
              <option
                v-for="(clinic, idx) in clinicsDropdownItems"
                :key="`clinic-item-${idx}`"
                :value="clinic.value"
              >
                {{clinic.text}}
              </option>
            </select>
          </div>
          <!-- End::Right col -->
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', patient)"
        >Confirm</a
      > -->
      <a v-if="!exportInvoicesLoading" class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm">
        Download (PDF)
      </a>
      <a v-else class="btn btn-lg btn-primary me-2 disabled" disabled>
        Generating statement...
      </a>
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import _ from "lodash";
import ApiService from "@/core/services/ApiService";

export default {
  emits: ["confirm", "cancel"],
  props: {
    clinicsDropdownItems: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
        exportInvoicesLoading: false,
        exportSummaryStatement: {
            month: null,
            clinic: null,
        },
    };
  },
  methods: {
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    exportInvoices() {
        this.exportInvoicesLoading = true;

        /**
         * - "from" first day of month
         * - "to" last day of month (0 = one day less than first day of the
         * month -> last day of the previous month)
         */
        const yearAndMonth = this.exportSummaryStatement.month;
        const from = new Date(`${yearAndMonth}-01T00:00:00Z`);
        const to = new Date(from.getFullYear(), from.getMonth() + 1, 0, 23, 59, 59, 999);

        ApiService
            .query("/invoices/batch", {
                params: {
                    from: from.toISOString().substring(0, 10),
                    to: to.toISOString().substring(0, 10),
                    clinic: this.exportSummaryStatement.clinic,
                    pdfSummary: 1,
                },
                responseType: 'arraybuffer',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/pdf',
                },
            })
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;

                let pdfFilename = `${yearAndMonth}-neoris-summary-statement`;
                const clinicId = this.exportSummaryStatement.clinic;
                if (clinicId) {
                  const clinicName = this.clinicsDropdownItems.find(e => e.value == clinicId).text;
                  if (clinicName) {
                    pdfFilename += `-${clinicName}`;
                  }
                }
                pdfFilename += '.pdf';

                link.setAttribute("download", pdfFilename);
                document.body.appendChild(link);
                link.click();
                this.$emit("confirm");
                this.exportInvoicesLoading = false;
            })
            .catch((err) => {
                console.log(err);
                this.exportInvoicesLoading = false;
            });
    },
  },
};
</script>

<style>
.modal-export-invoices.modal-content {
    min-height: 320px;
    max-height: 500px;
}
</style>
<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content modal-export-invoices"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        Export invoices
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <h5 class="fw-bolder mb-6">Export invoices (ZIP archive)</h5>
      <form @submit.prevent="exportInvoices" ref="form">
        <div class="d-flex flex-row row mb-4">
          <!-- Begin::Left col -->
          <div class="d-flex flex-column col-6 mb-5">
            <label for="from">From</label>
            <input
              type="date"
              class="form-control"
              id="from"
              v-model="exportInvoicesForm.from"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="to">To</label>
            <input
              type="date"
              class="form-control"
              id="to"
              v-model="exportInvoicesForm.to"
            />
          </div>
          <!-- End::Right col -->
        </div>
        <div class="d-flex flex-row row mb-12">
          <!-- Begin::Left col -->
          <div class="col-6 mb-5">
            <p>Payment status</p>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="exportInvoicesForm.paymentStatus" :value="null">
                Any
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="exportInvoicesForm.paymentStatus" value="completed">
                Payment completed
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" v-model="exportInvoicesForm.paymentStatus" value="rejected">
                Payment rejected
              </label>
            </div>
          </div>
          <div v-if="clinicsDropdownItems" class="d-flex flex-column col-6 mb-5">
            <p>Clinic</p>
            <select class="custom-select form-control" v-model="exportInvoicesForm.clinic">
              <option selected :value="null">All</option>
              <option
                v-for="(clinic, idx) in clinicsDropdownItems"
                :key="`clinic-item-${idx}`"
                :value="clinic.value"
              >
                {{clinic.text}}
              </option>
            </select>
          </div>
          <!-- End::Right col -->
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', patient)"
        >Confirm</a
      > -->
      <a v-if="!exportInvoicesLoading" class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm">
        Download (ZIP)
      </a>
      <a v-else class="btn btn-lg btn-primary me-2 disabled" disabled>
        Exporting...
      </a>
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import _ from "lodash";

import ApiService from "@/core/services/ApiService";

export default {
  emits: ["confirm", "cancel"],
  props: {
    clinicsDropdownItems: {
      type: Array,
      default: null,
    },
  },
  components: {
    // vSelect,
  },
  data() {
    return {
        exportInvoicesLoading: false,
        exportInvoicesForm: {
            from: null,
            to: null,
            clinic: null,
            paymentStatus: null,
        },
    };
  },
  methods: {
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    exportInvoices() {
        this.exportInvoicesLoading = true;
        ApiService
            .query("/invoices/batch", {
                params: {
                    from: this.exportInvoicesForm.from,
                    to: this.exportInvoicesForm.to,
                    clinic: this.exportInvoicesForm.clinic,
                    paymentStatus: this.exportInvoicesForm.paymentStatus,
                },
                responseType: 'arraybuffer',
            })
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'neoris-invoices.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.$emit("confirm");
                this.exportInvoicesLoading = false;
            })
            .catch((err) => {
                console.log(err);
                this.exportInvoicesLoading = false;
            });
    },
  },
};
</script>

<style>
.modal-export-invoices.modal-content {
    min-height: 320px;
    max-height: 500px;
}
</style>